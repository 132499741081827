
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import LoginForm from './modules/login.vue'
const tabList = ['登录', '注册']
export default defineComponent({
  name: 'Login',
  components: {
    LoginForm
  },
  setup () {
    const activeIndex = ref(0)
    // login or register
    const { query } = useRoute()
    activeIndex.value = query.type === '1' ? 1 : 0
    return { activeIndex, tabList }
  }
})
